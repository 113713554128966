<script>
import CouponDialogBase from "./CouponDialogBase";
import VxDialogView from "@/components/vx/VxDialogView";
import { mapActions } from "vuex";
import { get, debounce, pick, isEqual } from "lodash";
import { COUPON_DETAIL, UPDATE_COUPON } from "./graphql/queries";
import { hasSnackbarAccess } from "@/mixins/ui";
const formatPhone = (str) => (str || "").replace(/\+1/, "");

export default {
  name: "BouncebackEditDialog",
  components: {
    VxDialogView,
  },
  extends: CouponDialogBase,
  mixins: [hasSnackbarAccess],
  inheritAttrs: false,

  props: {
    id: { type: String, default: "" },
  },

  data: () => ({
    coupon: {},
    localCoupon: {
      id: undefined,
      name: undefined,
      email: undefined,
      cellphone: undefined,
      transactionId: undefined,
    },
  }),

  apollo: {
    coupon: {
      query: COUPON_DETAIL,
      variables() {
        return { id: this.id };
      },
      skip() {
        return !this.id;
      },
      update({ coupon }) {
        coupon.cellphone = formatPhone(coupon.cellphone);
        this.localCoupon = { ...coupon };
        return coupon;
      },
    },
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    title() {
      if (!this.localCoupon.number)
        return this.$t("bounceback.app.bouncebackEdit.titleGeneric");
      if (this.$vuetify.breakpoint.smAndDown) return this.localCoupon.number;

      return this.$t("bounceback.app.bouncebackEdit.title", {
        number: this.localCoupon.number,
      });
    },
    changes() {
      return ["name", "email", "cellphone", "transactionId"].reduce(
        (acc, field) => {
          const network = get(this, `coupon.${field}`);
          const local = get(this, `localCoupon.${field}`);
          const equal = isEqual(network, local);
          if (!equal) {
            acc[field] = local;
          }
          return acc;
        },
        {}
      );
    },
    hasChanges() {
      return !!Object.keys(this.changes).length;
    },
  },
  watch: {
    computedCellphone: debounce(function (v) {
      if (v && v.match(/\d{10}/)) {
        this.errors.cellphone = [];
      }
      if (v && !v.match(/\d{10}/)) {
        this.errors.cellphone = [
          this.$t("bounceback.app.bouncebackEdit.notValidPhone"),
        ];
      }
      if (!v) {
        this.errors.cellphone = [];
      }
    }, 300),
    computedEmail: debounce(function (v) {
      if (!v) {
        this.errors.email = [];
        return true;
      }
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const matchesRegex = v.match(emailRegex);
      if (v && matchesRegex) {
        this.errors.email = [];
      }
      if (v && !matchesRegex) {
        this.errors.email = [
          this.$t("bounceback.app.bouncebackEdit.notValidEmail"),
        ];
      }
    }, 300),
    changes() {
      ["name", "email", "cellphone", "transactionId"].reduce((acc, key) => {},
      {});
    },
  },

  methods: {
    // public
    refill(coupon) {
      coupon = pick(coupon, [
        "id",
        "name",
        "email",
        "cellphone",
        "transactionId",
        "storeId",
      ]);
      if (coupon.cellphone && coupon.cellphone.match(/^\+1/)) {
        coupon.cellphone = coupon.cellphone.replace(/^\+1/, "");
      }
      this.localCoupon = { ...coupon };
    },
    ...mapActions("coupons", ["updateCoupon"]),
    async onEditClick() {
      this.loading = true;
      try {
        const {
          data: {
            updateCoupon: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: UPDATE_COUPON,
          variables: {
            input: {
              id: this.coupon.id,
              ...this.changes,
            },
          },
        });
        if (errors.length) throw new Error(errors.join("; "));
        this.$router.go(-1);
      } catch (error) {
        console.error(`Error updating coupon ${this.coupon.id}`, error);
        this.showSnackbar({
          text: this.$t("bounceback.app.bouncebackEdit.couponUpdateError", [
            error,
          ]),
        });
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <VxDialogView
    :title="title"
    :retain-focus="false"
    :v-size="'medium'"
    :h-size="'xsmall'"
  >
    <template #actions>
      <v-btn
        :color="isSmallScreen ? null : 'primary'"
        text
        :disabled="!formValid || !hasChanges"
        :loading="loading"
        @click="onEditClick"
        >{{ $t("bounceback.app.bouncebackEdit.updateButton") }}</v-btn
      >
    </template>
    <v-form ref="editCouponForm" autocomplete="no">
      <v-text-field
        v-model="localCoupon.name"
        :label="$t('bounceback.app.bouncebackEdit.nameFieldLabel')"
        autocomplete="no"
      />
      <v-text-field
        v-model="localCoupon.email"
        :label="$t('bounceback.app.bouncebackEdit.emailFieldLabel')"
        :error-messages="errors.email"
        type="email"
        inputmode="email"
        autocomplete="no"
      />
      <v-text-field
        v-model="localCoupon.cellphone"
        prefix="+1"
        :label="$t('bounceback.app.bouncebackEdit.cellphoneFieldLabel')"
        mask="phone"
        type="tel"
        inputmode="numeric"
        pattern="[0-9]*"
        autocomplete="no"
        :error-messages="errors.cellphone"
      />
      <v-text-field
        v-model="localCoupon.transactionId"
        :label="$t('bounceback.app.bouncebackEdit.transactionIdLabel')"
        inputmode="numeric"
        pattern="[0-9]*"
        autocomplete="no"
        required
      />
    </v-form>
  </VxDialogView>
</template>
