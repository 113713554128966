import gql from "graphql-tag";

const COUPON_FRAGMENT = gql`
  fragment COUPON_FRAGMENT on Coupon {
    id
    name
    email
    cellphone
    number
    transactionId
    redeemed
    voided
    redeemAmount
    redeemMinSpend
    redeemStartOn
    redeemEndOn
    redeemable
    createdAt
    redeemedAt
    status
  }
`;

export const COUPONS_LIST_VIEW = gql`
  query COUPONS_LIST_VIEW(
    $storeId: ID!
    $sortBy: String!
    $descending: Boolean!
    $filter: String
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    coupons(
      storeId: $storeId
      sortBy: $sortBy
      descending: $descending
      filter: $filter
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalItems
      edges {
        cursor
        node {
          id
          name
          cellphone
          transactionId
          number
          status
          storeName
        }
      }
    }
  }
`;

export const COUPON_PRINT = gql`
  query COUPON_PRINT($id: ID!) {
    coupon(id: $id) {
      id
      transId
      customerName
      redeemStartOn
      redeemEndOn
      redeemAmount
      redeemMinSpend
      redeemLocations
      employeeName
      couponTerms
      couponCode
      storeCallAndText
      storeName
      storeAddress
      storeCity
      storeState
      storeZip
      storeWebsite
      storeNumber
      brand
    }
  }
`;

export const COUPON_DETAIL = gql`
  query COUPON_DETAIL($id: ID!) {
    coupon(id: $id) {
      ...COUPON_FRAGMENT
      storeName
    }
  }
  ${COUPON_FRAGMENT}
`;

// MUTATIONS
export const UPDATE_COUPON = gql`
  mutation UPDATE_COUPON($input: UpdateCouponInput!) {
    updateCoupon(input: $input) {
      coupon {
        ...COUPON_FRAGMENT
      }
      errors
    }
  }
  ${COUPON_FRAGMENT}
`;

export const CREATE_COUPON = gql`
  mutation CREATE_COUPON($input: CreateCouponInput!) {
    createCoupon(input: $input) {
      coupons {
        ...COUPON_FRAGMENT
      }
      errors
    }
  }
  ${COUPON_FRAGMENT}
`;

export const REDEEM_COUPON = gql`
  mutation REDEEM_COUPON($id: ID!) {
    redeemCoupon(id: $id) {
      coupon {
        ...COUPON_FRAGMENT
      }
      errors
    }
  }
  ${COUPON_FRAGMENT}
`;

// BOUNCEBACK CAMPAIGN QUERY
export const GET_CAMPAIGN_BY_ID = gql`
  query GET_CAMPAIGN_BY_ID($id: ID!) {
    bouncebackCampaign(id: $id) {
      id
      name
      earnStartOn
      earnEndOn
      redeemStartOn
      redeemEndOn
      earnMinSpend
      earnMultiple
      redeemMinSpend
      redeemAmount
      campaignType
      reminderText
      intent
      hasCoupons
      redeemable
      earnable
    }
  }
`;

export const GET_CAMPAIGN_LIST = gql`
  query GET_CAMPAIGN_LIST {
    bouncebackCampaigns {
      id
      name
      earnMinSpend
      redeemMinSpend
      redeemAmount
      redeemStartOn
      redeemEndOn
      earnStartOn
      earnEndOn
      redeemable
      earnable
      active
    }
  }
`;
