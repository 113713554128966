import { hasSnackbarAccess } from "@/mixins/ui";
export default {
  inheritAttrs: false,
  mixins: [hasSnackbarAccess],
  data: () => ({
    loading: false,
    localCoupon: {
      name: "",
      email: "",
      cellphone: "",
    },
    errors: {
      name: [],
      email: [],
      cellphone: [],
      transactionId: [],
    },
  }),
  computed: {
    computedCellphone() {
      return this.localCoupon.cellphone;
    },
    computedEmail() {
      return this.localCoupon.email;
    },
    payload() {
      return {
        coupon: {
          ...this.localCoupon,
        },
      };
    },
    formValid() {
      if (!this.localCoupon.transactionId) return false;
      if (Object.keys(this.errors).some((k) => this.errors[k].length > 0)) {
        return false;
      }
      return true;
    },
  },
  methods: {
    hide() {
      this.$emit("input", false);
    },
  },
};
